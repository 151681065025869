@import '~select2/dist/css/select2.css';
@import "~metro4/build/css/metro-all.min.css";
@import "~metro4/build/css/third-party/select2.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~jquery-confirm/dist/jquery-confirm.min.css";
@import "~basictable/basictable.css";
body {
  background-color: #eee;
}
body.theme1 #overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}
body.theme1 #navbar {
  z-index: 99999;
  height: 100% !important;
  position: fixed;
  background: #333;
  top: 0;
}
@media (max-width: 500px) {
  body.theme1 #navbar {
    height: 50px !important;
    border-bottom-right-radius: 5px;
  }
  body.theme1 #navbar.sidenav-simple-expand-fs {
    height: 100% !important;
    width: 250px !important;
  }
}
body.theme1 .sidenav-simple li:active {
  background-color: #009345;
}
body.theme1 .site {
  padding-left: 52px;
}
@media (max-width: 500px) {
  body.theme1 .site {
    padding-left: 0;
  }
}
body.theme1 .box {
  background-color: #fff;
  padding: 25px;
  border-radius: 3px;
  border-top: 3px solid #37b34a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
@media (max-width: 500px) {
  body.theme1 .box {
    padding: 15px;
  }
}
body.theme1 .button.primary {
  background-color: #009345;
  outline-color: #37b34a;
}
body.theme1 .button.primary.outline {
  background-color: transparent;
  border-color: #009345;
  color: #009345;
}
body.theme1 .button.primary:hover {
  background-color: #007a39;
  border-color: #00602d;
  outline-color: #37b34a;
}
body.theme1 .button.primary:focus {
  -webkit-box-shadow: 0 0 0 3px rgba(55, 179, 74, 0.6);
  box-shadow: 0 0 0 3px rgba(55, 179, 74, 0.6);
}
body.theme1 a {
  color: #009345;
}
body.theme1 a:hover {
  color: #00602d;
}
body.theme1 .dialog.primary {
  border-color: #009345;
}
body.theme1 .dialog.primary .dialog-title {
  background-color: #009345;
}
body.theme1 .dialog.primary .dialog-actions {
  border-top-color: #009345;
}
body.theme1 .alert:not(.button) {
  padding: 15px;
  margin-bottom: 10px;
}
body.theme1 .alert:not(.button).alert-success {
  background-color: #009345;
  border-left: 5px solid #007a39;
  color: #fff;
}
body.theme1 .alert:not(.button).alert-danger {
  background-color: #CE352C;
  border-left: 5px solid #b93028;
  color: #fff;
}
body.theme1 .alert:not(.button).alert-info {
  background-color: #30aae7;
  border-left: 5px solid #1ba1e3;
  color: #fff;
}
body.theme1 .form-error ul,
body.theme1 .form-error li {
  margin: 0;
}
body.theme1 .form-group ul:not(.d-menu) {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
body.theme1 .form-group ul:not(.d-menu) li {
  padding: 0;
  color: #CE352C;
}
@media (max-width: 768px) {
  body.theme1 table.bt tr {
    border: 2px solid #eee;
    margin-bottom: 1rem;
    display: block;
  }
  body.theme1 table.bt tr:before {
    content: attr(data-th);
    background-color: #eee;
    display: block;
    padding-left: 0.425rem;
    font-weight: bold;
  }
}
body.theme1 table.bt td {
  padding: 0;
}
body.theme1 table.bt td:before {
  background-color: #eee;
  padding-left: 0;
}
body.theme1 table.bt td .bt-content,
body.theme1 table.bt td:before {
  padding: 0.625rem;
}
@media (max-width: 768px) {
  body.theme1 table.bt td .bt-content,
  body.theme1 table.bt td:before {
    padding: 0.3rem 0.3rem 0.3rem 0.425rem;
  }
}
body.theme1 .autocomplete-suggestions {
  border: 1px #d9d9d9 solid;
  color: #1d1d1d;
  background: #FFF;
  overflow: auto;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
}
body.theme1 .autocomplete-suggestions strong {
  font-weight: bold;
  color: #009345;
}
body.theme1 .autocomplete-suggestions .autocomplete-suggestion {
  padding: 0.5rem 0.75rem;
  white-space: nowrap;
  overflow: hidden;
}
body.theme1 .autocomplete-suggestions .autocomplete-selected {
  background: #F0F0F0;
}
body.theme1 .autocomplete-suggestions .autocomplete-group {
  padding: 2px 5px;
}
body.theme1 .autocomplete-suggestions .autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}
body.theme1 video#QRCodeScanner {
  max-width: 100%;
}
body.theme1 .status {
  display: inline-block;
  border-radius: 50%;
  background: #CE352C;
  width: 8px;
  height: 8px;
  margin-bottom: 1px;
  margin-right: 8px;
}
body.theme1 .status.active {
  background: forestgreen;
}
body.theme1 .item {
  color: #fff;
  padding: 1px 4px;
  border-radius: 3px;
  font-size: 12px;
  vertical-align: text-bottom;
  background-color: #607d8b;
}
body.theme1 .item.instock {
  background-color: #009345;
}
body.theme1 .item.lent {
  background-color: #ff9447;
}
body.theme1 .item.gone {
  background-color: #607d8b;
}
body.theme1 .select2-container--default .select2-results__group {
  cursor: default;
  border: 0;
  text-decoration: none;
  background-color: rgba(29, 29, 29, 0.1);
  background-color: #f6f7f8;
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  color: #646464;
  font-weight: bold;
}
body.theme1 .select2-container--default .select2-results__option--highlighted[aria-selected],
body.theme1 .select .d-menu li.active > a,
body.theme1 .select .v-menu li.active > a {
  background-color: #009345;
  color: #fff;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: auto;
  text-decoration: none;
  font-size: 0.875rem;
  padding: 0 2rem 0 2.5rem;
  vertical-align: middle;
  border: none;
}
@media (max-width: 500px) {
  body.theme1 .input-mobile,
  body.theme1 .bt-content {
    width: 100% !important;
  }
}
body.theme2 #overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}
body.theme2 #navbar {
  z-index: 99999;
  height: 100% !important;
  position: fixed;
  background: #333;
  top: 0;
}
@media (max-width: 500px) {
  body.theme2 #navbar {
    height: 50px !important;
    border-bottom-right-radius: 5px;
  }
  body.theme2 #navbar.sidenav-simple-expand-fs {
    height: 100% !important;
    width: 250px !important;
  }
}
body.theme2 .sidenav-simple li:active {
  background-color: #EC5F5D;
}
body.theme2 .site {
  padding-left: 52px;
}
@media (max-width: 500px) {
  body.theme2 .site {
    padding-left: 0;
  }
}
body.theme2 .box {
  background-color: #fff;
  padding: 25px;
  border-radius: 3px;
  border-top: 3px solid #EC5F5D;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
@media (max-width: 500px) {
  body.theme2 .box {
    padding: 15px;
  }
}
body.theme2 .button.primary {
  background-color: #EC5F5D;
  outline-color: #EC5F5D;
}
body.theme2 .button.primary.outline {
  background-color: transparent;
  border-color: #EC5F5D;
  color: #EC5F5D;
}
body.theme2 .button.primary:hover {
  background-color: #e94846;
  border-color: #e7322f;
  outline-color: #EC5F5D;
}
body.theme2 .button.primary:focus {
  -webkit-box-shadow: 0 0 0 3px rgba(236, 95, 93, 0.6);
  box-shadow: 0 0 0 3px rgba(236, 95, 93, 0.6);
}
body.theme2 a {
  color: #EC5F5D;
}
body.theme2 a:hover {
  color: #e7322f;
}
body.theme2 .dialog.primary {
  border-color: #EC5F5D;
}
body.theme2 .dialog.primary .dialog-title {
  background-color: #EC5F5D;
}
body.theme2 .dialog.primary .dialog-actions {
  border-top-color: #EC5F5D;
}
body.theme2 .alert:not(.button) {
  padding: 15px;
  margin-bottom: 10px;
}
body.theme2 .alert:not(.button).alert-success {
  background-color: #EC5F5D;
  border-left: 5px solid #e94846;
  color: #fff;
}
body.theme2 .alert:not(.button).alert-danger {
  background-color: #CE352C;
  border-left: 5px solid #b93028;
  color: #fff;
}
body.theme2 .alert:not(.button).alert-info {
  background-color: #30aae7;
  border-left: 5px solid #1ba1e3;
  color: #fff;
}
body.theme2 .form-error ul,
body.theme2 .form-error li {
  margin: 0;
}
body.theme2 .form-group ul:not(.d-menu) {
  margin: 0;
  list-style-type: none;
  padding: 0;
}
body.theme2 .form-group ul:not(.d-menu) li {
  padding: 0;
  color: #CE352C;
}
@media (max-width: 768px) {
  body.theme2 table.bt tr {
    border: 2px solid #eee;
    margin-bottom: 1rem;
    display: block;
  }
  body.theme2 table.bt tr:before {
    content: attr(data-th);
    background-color: #eee;
    display: block;
    padding-left: 0.425rem;
    font-weight: bold;
  }
}
body.theme2 table.bt td {
  padding: 0;
}
body.theme2 table.bt td:before {
  background-color: #eee;
  padding-left: 0;
}
body.theme2 table.bt td .bt-content,
body.theme2 table.bt td:before {
  padding: 0.625rem;
}
@media (max-width: 768px) {
  body.theme2 table.bt td .bt-content,
  body.theme2 table.bt td:before {
    padding: 0.3rem 0.3rem 0.3rem 0.425rem;
  }
}
body.theme2 .autocomplete-suggestions {
  border: 1px #d9d9d9 solid;
  color: #1d1d1d;
  background: #FFF;
  overflow: auto;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.35);
}
body.theme2 .autocomplete-suggestions strong {
  font-weight: bold;
  color: #EC5F5D;
}
body.theme2 .autocomplete-suggestions .autocomplete-suggestion {
  padding: 0.5rem 0.75rem;
  white-space: nowrap;
  overflow: hidden;
}
body.theme2 .autocomplete-suggestions .autocomplete-selected {
  background: #F0F0F0;
}
body.theme2 .autocomplete-suggestions .autocomplete-group {
  padding: 2px 5px;
}
body.theme2 .autocomplete-suggestions .autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}
body.theme2 video#QRCodeScanner {
  max-width: 100%;
}
body.theme2 .status {
  display: inline-block;
  border-radius: 50%;
  background: #CE352C;
  width: 8px;
  height: 8px;
  margin-bottom: 1px;
  margin-right: 8px;
}
body.theme2 .status.active {
  background: forestgreen;
}
body.theme2 .item {
  color: #fff;
  padding: 1px 4px;
  border-radius: 3px;
  font-size: 12px;
  vertical-align: text-bottom;
  background-color: #607d8b;
}
body.theme2 .item.instock {
  background-color: #009345;
}
body.theme2 .item.lent {
  background-color: #ff9447;
}
body.theme2 .item.gone {
  background-color: #607d8b;
}
body.theme2 .select2-container--default .select2-results__group {
  cursor: default;
  border: 0;
  text-decoration: none;
  background-color: rgba(29, 29, 29, 0.1);
  background-color: #f6f7f8;
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  color: #646464;
  font-weight: bold;
}
body.theme2 .select2-container--default .select2-results__option--highlighted[aria-selected],
body.theme2 .select .d-menu li.active > a,
body.theme2 .select .v-menu li.active > a {
  background-color: #EC5F5D;
  color: #fff;
  font-weight: 700;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: auto;
  text-decoration: none;
  font-size: 0.875rem;
  padding: 0 2rem 0 2.5rem;
  vertical-align: middle;
  border: none;
}
@media (max-width: 500px) {
  body.theme2 .input-mobile,
  body.theme2 .bt-content {
    width: 100% !important;
  }
}
