@import '~select2/dist/css/select2.css';
@import "~metro4/build/css/metro-all.min.css";
@import "~metro4/build/css/third-party/select2.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~jquery-confirm/dist/jquery-confirm.min.css";
@import "~basictable/basictable.css";

@red: #CE352C;

body {
  background-color: #eee;

  &.theme1 {
    @primary: #009345;
    @secondary: #37b34a;
    .mainThemeDependentCss();
  }

  &.theme2 {
    @primary: #EC5F5D;
    @secondary: #EC5F5D;
    .mainThemeDependentCss();
  }
}

.mainThemeDependentCss() {
  #overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: none;
  }

  #navbar {
    z-index: 99999;
    height: 100% !important;
    position: fixed;
    background: #333;
    top: 0;

    @media(max-width: 500px) {
      height: 50px !important;
      border-bottom-right-radius: 5px;

      &.sidenav-simple-expand-fs {
        height: 100% !important;
        width: 250px !important;
      }
    }
  }

  .sidenav-simple li:active {
    background-color: @primary;
  }

  .site {
    padding-left: 52px;

    @media(max-width: 500px) {
      padding-left: 0;
    }
  }

  .box {
    background-color: #fff;
    padding: 25px;
    border-radius: 3px;
    border-top: 3px solid @secondary;
    box-shadow: 0 2px 4px rgba(0,0,0,.15);

    @media(max-width: 500px) {
      padding: 15px;
    }
  }

  .button {
    &.primary {
      background-color: @primary;
      outline-color: @secondary;

      &.outline {
        background-color: transparent;
        border-color: @primary;
        color: @primary;
      }

      &:hover {
        background-color: darken(@primary, 5%);
        border-color: darken(@primary, 10%);
        outline-color: @secondary;
      }

      &:focus {
        -webkit-box-shadow: 0 0 0 3px fadeOut(@secondary, 40%);
        box-shadow: 0 0 0 3px fadeOut(@secondary, 40%);
      }
    }
  }

  a {
    color: @primary;

    &:hover {
      color: darken(@primary, 10%);
    }
  }

  .dialog {
    &.primary {
      border-color: @primary;

      .dialog-title {
        background-color: @primary;
      }

      .dialog-actions {
        border-top-color: @primary;
      }
    }
  }

  .alert:not(.button) {
    padding: 15px;
    margin-bottom: 10px;

    &.alert-success {
      background-color: @primary;
      border-left: 5px solid darken(@primary, 5%);
      color: #fff;
    }

    &.alert-danger {
      background-color: @red;
      border-left: 5px solid darken(@red, 5%);
      color: #fff;
    }

    &.alert-info {
      background-color: darken(#5ebdec, 10%);
      border-left: 5px solid darken(#5ebdec, 15%);
      color: #fff
    }
  }
  .form-error {
    ul, li {
      margin: 0;
    }
  }

  .form-group ul:not(.d-menu) {
    margin: 0;
    list-style-type: none;
    padding: 0;

    li {
      padding: 0;
      color: @red;
    }
  }

  table.bt {
    @media(max-width: 768px) {
      tr {
        border: 2px solid #eee;
        margin-bottom: 1rem;
        display: block;

        &:before {
          content: attr(data-th);
          background-color: #eee;
          display: block;
          padding-left: .425rem;
          font-weight: bold;
        }
      }
    }

    td {
      padding: 0;

      &:before {
        background-color: #eee;
        padding-left: 0;
      }

      .bt-content, &:before {
        padding: .625rem;

        @media(max-width: 768px) {
          padding: .3rem .3rem .3rem .425rem;
        }
      }
    }
  }

  .autocomplete-suggestions {
    border: 1px #d9d9d9 solid;
    color: #1d1d1d;
    background: #FFF;
    overflow: auto;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.35);

    strong {
      font-weight: bold;
      color: @primary;
    }

    .autocomplete-suggestion {
      padding: .5rem .75rem;
      white-space: nowrap;
      overflow: hidden;
    }
    .autocomplete-selected {
      background: #F0F0F0;
    }
    .autocomplete-group {
      padding: 2px 5px;

      strong {
        display: block;
        border-bottom: 1px solid #000;
      }
    }
  }

  video#QRCodeScanner {
    max-width: 100%;
  }

  .status {
    display: inline-block;
    border-radius: 50%;
    background: @red;
    width: 8px;
    height: 8px;
    margin-bottom: 1px;
    margin-right: 8px;

    &.active {
      background: forestgreen;
    }
  }

  .item {
    color: #fff;
    padding: 1px 4px;
    border-radius: 3px;
    font-size: 12px;
    vertical-align: text-bottom;

    background-color: #607d8b;

    &.instock {
      background-color: #009345;
    }

    &.lent {
      background-color: #ff9447;
    }

    &.gone {
      background-color: #607d8b;
    }
  }

  .select2-container--default .select2-results__group {
    background-color: #f6f7f8;
    cursor: default;
    border: 0;
    text-decoration: none;
    background-color: rgba(29, 29, 29, 0.1);
    background-color: #f6f7f8;
    font-size: 0.75rem;
    line-height: 0.875rem;
    padding: 0.25rem 0.5rem 0.25rem 1rem;
    color: #646464;
    font-weight: bold;
  }

  .select2-container--default .select2-results__option--highlighted[aria-selected],
  .select .d-menu li.active>a, .select .v-menu li.active>a {
    background-color: @primary;
    color: #fff;
    font-weight: 700;
    height: 32px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: auto;
    text-decoration: none;
    font-size: .875rem;
    padding: 0 2rem 0 2.5rem;
    vertical-align: middle;
    border: none;
  }

  .input-mobile,
  .bt-content {
    @media(max-width: 500px) {
      width: 100% !important;
    }
  }
}